import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ChevronLeftRounded as LeftChevron, CloseRounded as Cross, DoneRounded as Tick, ChevronRightRounded as RightChevron } from '@material-ui/icons'
import useBooking from '../Helpers/useBooking'

import styled from 'styled-components'
import { motion } from 'framer-motion'
// import { RightArrow, Tick } from './Icons'
import { SecondaryBtn, SubtleListItem } from 'zyppd-bits'

const Footer = styled.div`
    position: absolute;
    z-index: 11;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3em;
    display: flex;
    background: ${({ theme }) => theme.foreground};
    box-shadow: 0 -2px 3px 3px rgba(0,0,0,0.05);
    .dots-container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        z-index: 10;
        
    }
    .container{
        width: 100%; 
    }
`

const TrackingMenu = styled(motion.ul)`
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0; 
    bottom: 0;
    .background{
        background: ${({ theme }) => theme.middleground};
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0;
        right: 0; 
        bottom: 0;
    }
    .content{
        position: absolute;
        left: 2em; 
        bottom: 3em;
        min-width: calc(100% - 2em);
        min-height: 100px;
        padding: 1em;
        margin: 0 -1em;
        background: ${({ theme }) => theme.background};
        border-radius: ${({ theme }) => theme.borderRadius};
        box-shadow: ${({ theme }) => theme.shadow};
        .buttons {
            > * {
                height: 100%;
            }
        } 
    }
`



// border: ${({ complete, theme }) => !complete ? `2px solid ${theme.brandColor}` : 'none'};
const BookingDot = styled.div`
    border-radius: 50%;
    display: block;
    background: ${({ complete, theme }) => complete ? theme.brandColor : 'none'};
    border: ${({ theme }) => `2px solid ${theme.brandColor}`};
    width: 10px;
    height: 10px;
    transform: translate( 0, ${({ current }) => current ? '-80%' : 0});
    transition: .2s ease-out;
    margin: 0 .5em;
`

export const BookingProgress = () => {

    const location = useLocation()
    const currentStep = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    // const history = useHistory()
    const { bookingInfo, stepsInit, goToPrevious } = useBooking()
    const [parsedSteps, setSteps] = useState([])


    async function getCompletedSteps(steps) {

        const withCompletedSteps = steps.map(step => {

            let data = {
                ...step
            }

            if (bookingInfo[step.title] && Object.keys(bookingInfo[step.title]).length) {
                data = {
                    ...step,
                    complete: true,
                    text: bookingInfo[step.title].title
                }
            } else {
                data = {
                    ...step,
                    complete: false
                }
            }

            return data
        })

        return (withCompletedSteps)
    }

    async function getCurrentStep(steps) {
        const checkCurrentStep = steps.map(step => {
            let data = { ...step }
            if (step.title === currentStep) {
                data = {
                    ...data,
                    current: true,
                }
            }
            return data
        })

        return checkCurrentStep
    }


    async function updateSteps() {

        const withCompletedSteps = await getCompletedSteps(stepsInit
            .filter(section => section.display !== false))
        const withCurrentStep = await getCurrentStep(withCompletedSteps)
        setSteps(withCurrentStep)
    }


    useEffect(() => {
        updateSteps()
    }, [bookingInfo])

    const [isOpen, setIsOpen] = useState(false)

    function setText(step) {

        if (step.title === 'customer_details') {
            return JSON.parse(step.text).customer_name
        } else if (step.title === 'customer_address') {
            return JSON.parse(step.text).postcode
        }
        return step.text
    }

    const menu = {
        open: {
            y: 0,
            opacity: 1,
            transition: {
                when: "beforeChildren",
                type: 'tween'
            }
        },
        closed: {
            opacity: 0,
            y: '120%'
        },
    }

    const menuItem = {
        open: {
            opacity: 1,
            x: 0
        },
        closed: {
            opacity: 0,
            x: 20
        }
    }

    return (
        <>
            <Footer>
                <motion.div
                    className={'container'}

                >


                    <div
                        onClick={() => setIsOpen(!isOpen)}
                        className='dots-container'
                        style={{
                            position: 'absolute',
                            top: '0', left: '0', right: '0', bottom: '0',
                        }}
                    >

                        {parsedSteps && parsedSteps.map((step, i) => {
                            return (
                                <BookingDot
                                    key={i}
                                    current={step.current}
                                    complete={step.complete}
                                >
                                </BookingDot>
                            )
                        })}
                    </div>
                </motion.div>

            </Footer>
            <TrackingMenu
                initial={false}
                animate={isOpen ? "open" : "closed"}
                variants={menu}
            >
                <div className="background"
                    onClick={() => {
                        setIsOpen(false);
                    }}
                ></div>
                <div className="content">

                    <div
                        className="buttons"
                        style={{
                            display: 'flex',
                            marginBottom: '.5em'
                        }}
                    >
                        {currentStep !== 'service' &&
                            <SecondaryBtn
                                onClick={() => {
                                    goToPrevious()
                                    setIsOpen(!isOpen)
                                }}
                                fullWidth={true}
                                style={{ marginBottom: '.5em' }}
                            >
                                <LeftChevron />
                            Go back
                        </SecondaryBtn>
                        }
                        <SecondaryBtn
                            onClick={() => setIsOpen(false)}
                            style={{ marginLeft: '.5em' }}
                        >
                            <Cross />
                        </SecondaryBtn>
                    </div>


                    {parsedSteps.map(step => (
                        <SubtleListItem
                            // className={step.current ? 'current' : step.complete ? 'complete' : ''}
                            animate={isOpen ? "open" : "closed"}
                            variants={menuItem}
                            key={step.title}
                        >
                            {step.current &&
                                <RightChevron />
                            }
                            {step.complete ?
                                <>

                                    <Tick />
                                    <div>
                                        <small>{step.name}</small>
                                        <h4>
                                            {setText(step)}
                                        </h4>
                                    </div>
                                </>
                                :
                                <p>
                                    {step.name}
                                </p>

                            }

                        </SubtleListItem>
                    ))}
                </div>

            </TrackingMenu>
        </>
    )
}