import React, { useEffect, useState, useContext } from 'react'
import { List } from '../../Components/Modules'
import { ShopContext } from '../ShopContext'
import useBooking from '../../Helpers/useBooking'


export default function Staff({ id, bookingInfo, updateBooking }) {

    const service = bookingInfo.service.id
    const { shopInfo } = useContext(ShopContext)
    const [renderStaff, setRenderStaff] = useState()
    const { hasLocations } = useBooking();


    function getStaffThatOfferService(serviceId, staff) {

        const staffThatOfferService = staff.filter((member) => {
            if (member.services && member.services.includes(serviceId) && member.disabled !== true) {
                return member
            }
        })

        // The option to not choose a specific staff is currently on hold. shopInfo.allowAnyStaff should always be set to false
        if (staffThatOfferService.length > 1 && shopInfo.allowAnyStaff) {
            const anyStaff = {
                title: "I don't mind",
                staffId: '000',
            }
            return [anyStaff, ...staffThatOfferService]
        }
        return [...staffThatOfferService]

    }

    function staffAtThisLocation(locationId, staff) {
        const availableStaff = bookingInfo.location ? shopInfo.staff.filter(member => member.location && member.location.includes(bookingInfo.location.id)) : staff
        return availableStaff
    }

    function getStaff() {
        const { staff } = shopInfo
        const serviceId = bookingInfo.service.id
        const staffThatOfferService = getStaffThatOfferService(serviceId, staff)
        const staffAtLocation = hasLocations ? staffAtThisLocation(serviceId, staffThatOfferService) : staffThatOfferService
        setRenderStaff(staffAtLocation)

    }


    useEffect(() => {
        bookingInfo.service !== null && getStaff()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingInfo, service])

    const updateContext = (val) => {

        const alternateStaff = renderStaff.filter(staff => {
            if (staff.staffId !== '000' && staff.services.includes(bookingInfo.service.id)) {
                return staff
            }
        })

        updateBooking(
            {
                action: {
                    type: 'redirect',
                    to: `next-step`
                },
                info: {
                    staff: {
                        title: val.title,
                        id: val.id || "000",
                        staffDaysOff: val.daysOff || false,
                    },
                },

            },
            { //Update Details
                category: 'staff',
                toastMessage: `Staff: ${val.title}`,
            },
            { alternateStaff: [...alternateStaff] }
        )
    }


    return (
        <>
            {renderStaff &&
                <List
                    handleClick={updateContext}
                    type='service'
                    options={[...renderStaff]}
                // next={`/${id}/booking/date`}
                />
            }
        </>
    )
}