import React, { useContext, useState, useEffect } from 'react'
import { List } from '../../Components/Modules'
import { ShopContext } from '../ShopContext'
import { priceToString } from '../../Helpers/Helpers'
import useBooking from '../../Helpers/useBooking'

export default function Service({ id, updateBooking }) {

    const [output, setOutput] = useState([]);

    const { shopInfo } = useContext(ShopContext)
    const { services } = shopInfo;

    const { hasStaff, chosenLocation } = useBooking();

    function getServicesAtChosenLocation(servs) {
        if (!chosenLocation) return servs
        let servicesAtThisLocation = chosenLocation.services || []
        // Remove any services that are not available at the selected location
        servs = servs.filter(serv => servicesAtThisLocation.includes(serv.id))
        return servs
    };

    function getServicesThatHaveStaff(servs) {
        if (!hasStaff) return servs
        // Remove any services that do not have staff that offer that service; unless shop does not have staff
        const staffServices = shopInfo.staff.map(member => member.services).flat()
        servs = servs.filter(serv => staffServices.includes(serv.id))
        return servs
    }


    useEffect(() => {

        let servs = services.filter(service => service.disabled !== true)
        servs = getServicesAtChosenLocation(servs)
        servs = getServicesThatHaveStaff(servs)

        const formattedServices = servs.map(service => {
            const timeLength = service.amountOfTime * shopInfo.timeSlotLength // if the shop is remote take away the length of 1 timeslot. We add 1 to the amountOfTime value to remote shops services to allow for travel time
            return {
                ...service,
                price: priceToString(service.price),
                timeLength
            }
        });

        setOutput(formattedServices)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenLocation])


    const updateContext = (val) => {
        updateBooking({
            action: {
                type: 'redirect',
                to: 'next-step'
            },
            info: {
                service: {
                    title: val.title,
                    id: val.id,
                    price: val.price * 100,
                    deposit: val.deposit ? val.deposit : false,
                    amountOfTime: val.amountOfTime,
                },
            },
        },
            { //Update Details
                category: 'service',
                toastMessage: `Service: ${val.title}`
            }
        )
    }

    return output ? (

        <List
            handleClick={updateContext}
            type='service'
            options={[...output]}
        />

    ) : <></>;
}