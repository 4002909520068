import React, { useState, useEffect, useContext } from "react";
import firebase from 'firebase'
import { ZyppdComponents, OneOffNotification } from 'zyppd-bits'
import LoadingScreen from './Components/LoadingScreen'
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Toaster } from 'react-hot-toast'

import CreateBookingContainer from './Booking/CreateBookingContainer'

export const BusinessContext = React.createContext([{}, () => { }]);

export default function App() {
  const [loading, setLoading] = useState(true);
  const [businessInfo, setBusinessInfo] = useState(false);
  const [theme, setTheme] = useState(['white', 'whitesmoke']);
  const db = firebase.firestore();


  useEffect(() => {
    db.collection(`business_info`)
      .doc('business_details')
      .onSnapshot((doc) => {
        setBusinessInfo(doc.data())
      })
  }, []);

  useEffect(() => {
    businessInfo && setLoading(false);
  }, [businessInfo])

  return (

    <BusinessContext.Provider value={{ businessInfo, setBusinessInfo, setTheme }}>
      <ZyppdComponents
        brandColor={businessInfo.brandColor}
        range={theme}
      >
        {loading ? <LoadingScreen /> : <></>}
        <OneOffNotification
          style={{ zIndex: '5' }}
          name="notified_of_cookies"
        >
          <p>
            Like most websites, zyppd.in uses cookies to enhance your experience and make our platform better.
            <br /> We don't use cookies to track you, and we don't sell your data to third parties.
            <br /> By continuing to use zyppd.in you're agreeing to the use of these cookies.
          </p>
          <p>
            <a href="https://zyppd.in/cookies/" target="_blank" rel="noopener noreferrer">
              cookie policy
            </a>
          </p>
        </OneOffNotification>

        <Router>
          <div>
            <Switch>

              <Route exact path="/">
                <Home />
              </Route>

              <Route path="/:id" children={<CreateBookingContainer businessInfo={businessInfo} />} />

            </Switch>
          </div>
          <Toaster
            position="bottom-center"
          />
        </Router>
      </ZyppdComponents>
    </BusinessContext.Provider>
  );
}


function Home() {

  const { businessInfo } = useContext(BusinessContext)
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>

      <Ti>{businessInfo.businessName}</Ti>
    </div>
  )
}

const Ti = styled.h1`
  color: ${({ theme }) => theme.brandColor}
`

