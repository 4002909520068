import React, { useState, useEffect, useContext } from 'react'
import { List } from '../../Components/Modules'
import { ShopContext } from '../ShopContext'
import LoadingScreen from '../../Components/LoadingScreen'
import firebase from '../../project-config'
import dayjs from 'dayjs'
import { Modal, PrimaryBtn } from 'zyppd-bits'
import { ChevronLeftRounded as LeftChevron } from '@material-ui/icons'
import useBooking from '../../Helpers/useBooking'


export default function Time({ id, bookingInfo, updateBooking, location }) {

    const currentTimeZone = 'Europe/London'
    const db = firebase.firestore()
    const { shopInfo } = useContext(ShopContext)

    const { goToPrevious, chosenLocation } = useBooking()

    const [bookedSlots, setBookedSlots] = useState([])
    const [timeSlots, setTimeSlots] = useState([])
    const [renderTimes, setRenderTimes] = useState()


    async function makeTimes(chosenDay) { // 1. output an array of objects with an incremented time (every 'timeSlotLength from shopInfo' minutes) from shopInfos firstBookingtime and lastBookingTime
        const d = dayjs(chosenDay)
        // .tz(currentTimeZone)
        // .hour(0);
        const locationInfo = typeof chosenLocation === 'object' ? chosenLocation.days : shopInfo.days
        const chosenDayInfo = locationInfo.find(day => day.day === dayjs(chosenDay).tz(currentTimeZone).day())
        let openingTime = chosenDayInfo.firstBookingTime.split(":")
        const closingTime = chosenDayInfo.lastBookingTime.split(":")

        let timesArr = []
        const TIME_SLOT_LENGTH = shopInfo.timeSlotLength || 30

        const MILLIS_IN_AN_HOUR = 3600000
        const MILLIS_IN_A_MINUTE = 60000

        //TODO: during BST, check that this still renders times as expected when clocks change back to winter time. 
        // const DAYLIGHT_SAVING_DIFFERENCE = dayjs(chosenDay).get('hour').valueOf() * MILLIS_IN_AN_HOUR

        const startTime = dayjs(d + (Number(openingTime[0]) * MILLIS_IN_AN_HOUR) + (Number(openingTime[1]) * MILLIS_IN_A_MINUTE))
        const endTime = dayjs(d + (Number(closingTime[0]) * MILLIS_IN_AN_HOUR) + (Number(closingTime[1]) * MILLIS_IN_A_MINUTE))

        let timeVal = 0
        let diff = endTime.valueOf() - startTime.valueOf()

        while (timeVal <= diff) {
            const timeSlot = timeVal <= TIME_SLOT_LENGTH ? startTime : startTime.add(timeVal, 'milliseconds')
            timesArr.push({
                title: timeSlot.tz(currentTimeZone).format('LT'),
                id: timeSlot.valueOf(),
            })
            timeVal += TIME_SLOT_LENGTH * 60000 // convert to millis
        }



        if (shopInfo.allowBookingPassedClosingTime) {
            return timesArr
        } else {
            let removePreCloseBookingTimes = timesArr.slice(0, timesArr.length - bookingInfo.service.amountOfTime)
            return removePreCloseBookingTimes
        }
    }

    function removePassedTimes(timeSlots) { //2. Remove any time that has just passed

        let d = dayjs().tz(currentTimeZone)
        // If the shop has a minimum advanced booking time, move the time forward by that much
        if (shopInfo.minimumAdvanceBooking) {
            d = dayjs().tz(currentTimeZone).add(shopInfo.minimumAdvanceBooking, 'm')
        }

        const currentTime = d.valueOf()

        const futureTimes = timeSlots.filter(slot => {
            return Number(slot.id) > Number(currentTime)
        })
        return futureTimes
    }

    const disableLunchTime = async (timesArr) => { //3. 
        const removeColonRegex = new RegExp("[:]", "g")

        return timesArr.map(time => {
            let slotTime = Number(time.title.replace(removeColonRegex, ""))

            if (slotTime >= shopInfo.lunchTime[0] && slotTime < shopInfo.lunchTime[1]) {
                return {
                    ...time,
                    type: 'disabled',
                    description: 'Closed for lunch'
                }
            } else return time

        })
    }

    async function setFullyBookedSlots(timesArr, bookings) {

        let numOfSlotsAvailable = bookingInfo.alternateStaff.length
        let serviceLength = bookingInfo.service.amountOfTime
        const uniqueBookingsLength = Array.from(new Set(bookings.map(b => b.publicBookingId))).length
        let { maxBookingsPerDay } = shopInfo  // If the shop only wants a certain number of bookings per day, add this amount as a value in firestore

        if (shopInfo.staff === undefined || bookingInfo.alternateStaff <= 1) {
            numOfSlotsAvailable = shopInfo.maxBookingsPerSlot
        }
        return timesArr.map((time, i) => {

            const numOfBookingsAtThisTime = bookings.filter(booking => {
                return booking.time === time.id
            })


            if (numOfBookingsAtThisTime.length >= numOfSlotsAvailable || (maxBookingsPerDay && (uniqueBookingsLength >= maxBookingsPerDay))) {
                return {
                    ...time,
                    type: 'disabled',
                    message: 'Fully booked'
                }
            } else {

                for (let j = 0; j < serviceLength; j++) { // jump to future slots and check they are also not booked, so the booking doesn't overlap

                    const index = i + j
                    if (timesArr[index]) {

                        const bookingsInFollowingSlots = bookings.filter(booking => {
                            return booking.time === timesArr[index].id
                        })

                        if (bookingsInFollowingSlots.length >= numOfSlotsAvailable) {
                            let message = `Fully booked ${process.env.REACT_APP_ENVIRONMENT === 'dev' ? `at ${timesArr[index].title}` : ''}`
                            return {
                                ...time,
                                type: 'disabled',
                                message
                            }
                        }
                    }
                }

            }

            return {
                ...time,
            }

        })
    }

    async function setTimesChosenStaffIsBooked(timesArr, bookings, chosenStaff) {
        let serviceLength = bookingInfo.service.amountOfTime

        return timesArr.map((time, i) => {

            // const chosenStaffIsBooked = bookings.find(booking => booking.staffId === chosenStaff.id && booking.time === time.id)
            if (time.type === 'disabled') {
                return {
                    ...time,
                }
            }

            let data;

            for (let j = 0; j < serviceLength; j++) { // jump to future slots and check they are also not booked, so the booking doesn't overlap
                const index = i + j + (shopInfo.remote ? 1 : 0)
                if (timesArr[index]) { // if the time slot exists
                    const chosenStaffIsBooked = bookings.find(booking => booking.staffId === chosenStaff.id && booking.time === timesArr[index].id)
                    if (chosenStaffIsBooked) {
                        const alternateStaff = getAlternateStaff(timesArr[index], chosenStaff, bookings, time.title)
                        if (alternateStaff) {
                            data = {
                                ...time,
                                ...alternateStaff
                            }
                        } else {
                            data = {
                                ...time,
                                type: 'disabled',
                                description: `${chosenStaff.title} is booked`
                            }
                        }
                    }

                }
            }

            if (data) {
                return data
            }
            return {
                ...time,
            }

        })
    }

    // const { date } = useSearchParams()
    const date = bookingInfo && bookingInfo.date && bookingInfo.date.id

    const [staff] = useState(() => bookingInfo.staff ? bookingInfo.staff.id : '000')


    function getAlternateStaff(time, chosenStaff, bookings, actualTime) {

        const alternateStaff = bookingInfo.alternateStaff;

        const availableAlternateStaff = alternateStaff.filter(member => member.id !== chosenStaff.id && (member.daysOff && !member.daysOff.includes(new Date(Number(date)).getDay()))) // Staff that are not the chosen one and are working on this day
        const alternateStaffThatAreBookedAtThisTime = bookings.filter(booking => booking.time === time.id && booking.staffId !== chosenStaff.id).map(booking => booking.staffId)
        const availableStaff = availableAlternateStaff.filter(member => !alternateStaffThatAreBookedAtThisTime.includes(member.id))

        if (availableStaff.length > 0) {

            let messageAppendix = `or ${availableStaff.length - 1} other${availableStaff.length - 1 > 1 ? 's' : ''}`

            let bookingIndex = renderTimes && renderTimes.findIndex((slot, index) => time.id === slot.id)
            let overflowBookings = []

            for (let i = 1; i < bookingInfo.service.amountOfTime; i++) {
                bookingIndex && renderTimes[bookingIndex + i] !== null && overflowBookings.push(renderTimes[bookingIndex + i])
            }

            return {
                type: 'warning',
                message: `Switch to ${availableStaff[0].title} ${availableStaff.length - 1 ? messageAppendix : ''}`,
                switchWhat: 'staff',
                switchFrom: 'time',
                switchOptions: availableStaff,
                overflowBookings,
                description: chosenStaff.title + ' is booked'
            }
        }

        return null

    }



    async function disableBookedTimes(timeSlots, bookedTimes) {

        const withBookedSlots = await setFullyBookedSlots(timeSlots, bookedTimes)

        if (staff === '000' || staff === undefined) return withBookedSlots

        if (staff !== '000') {
            const chosenStaff = shopInfo.staff.find(member => member.id === staff) // gets staff that matches id in url search param
            return await setTimesChosenStaffIsBooked(withBookedSlots, bookedTimes, chosenStaff)
        }

    }

    const setTimes = async (chosenDay) => {

        const allTimeSlots = await makeTimes(chosenDay);
        const timeSlots = await removePassedTimes(allTimeSlots)
        const withLunchTimeDisabled = shopInfo.lunchTime ? await disableLunchTime(timeSlots) : timeSlots

        setTimeSlots(withLunchTimeDisabled)
        if (bookingInfo) {
            try {
            } catch (error) {
                console.error(error)
                console.warn("Redirect to start")
            }
        }

    }


    async function getBookings(chosenDay) {
        if (!chosenDay) return;

        const TWO_HOUR_IN_MILLIS = 3600000 * 24
        // if (bookingInfo.location && bookingInfo.location.id) {
        //     // Get bookings only for chosen location
        //     db.collection(`public/${shopInfo.id}/bookings`)
        //         .where('date', '>', chosenDay - TWO_HOUR_IN_MILLIS) // Adds a time window, to allow for changing clocks
        //         .where('date', '<', chosenDay + TWO_HOUR_IN_MILLIS)
        //         .where('locationId', '==', bookingInfo.location.id)
        //         .onSnapshot(querySnapshot => {
        //             let data = []
        //             querySnapshot.forEach((doc) => {
        //                 data.push(doc.data())
        //             })
        //             setBookedSlots(data)
        //         })

        //         db.collection(`public/${shopInfo.id}/bookings`)
        //         // .where('date', '>', chosenDay - TWO_HOUR_IN_MILLIS) // Adds a time window, to allow for changing clocks
        //         // .where('date', '<', chosenDay + TWO_HOUR_IN_MILLIS)
        //         .where('date', '==', dayjs(chosenDay).format('L'))
        //         .where('locationId', '==', bookingInfo.location.id)
        //         .onSnapshot(querySnapshot => {
        //             // let data = []
        //             querySnapshot.forEach((doc) => {
        //                 data.push(doc.data())
        //             })
        //             setBookedSlots(prevState => [...prevState, ...data])
        //             return data
        //         })

        // } else {
        db.collection(`public/${shopInfo.id}/bookings`)
            .where('date', '>', chosenDay - TWO_HOUR_IN_MILLIS) // Adds a time window, to allow for changing clocks
            .where('date', '<', chosenDay + TWO_HOUR_IN_MILLIS)
            // .where('date', '==', dayjs(chosenDay).format('L'))
            .onSnapshot(querySnapshot => {
                let data = []
                querySnapshot.forEach((doc) => {
                    data.push(doc.data())
                })
                setBookedSlots(prevState => [...prevState, ...data])
            })
        db.collection(`public/${shopInfo.id}/bookings`)
            // .where('date', '>', chosenDay - TWO_HOUR_IN_MILLIS) // Adds a time window, to allow for changing clocks
            // .where('date', '<', chosenDay + TWO_HOUR_IN_MILLIS)
            .where('date', '==', dayjs(chosenDay).format('L'))
            .onSnapshot(querySnapshot => {
                let data = []
                querySnapshot.forEach((doc) => {
                    data.push(doc.data())
                })
                setBookedSlots(prevState => [...prevState, ...data])
            })

        // }
    }

    const parseBookingsIntoTimes = async () => {
        const timeSlotsWithBookings = await disableBookedTimes(timeSlots, bookedSlots)
        setRenderTimes(timeSlotsWithBookings)

    }

    useEffect(() => {

        bookingInfo && timeSlots.length > 0 && parseBookingsIntoTimes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookedSlots, location, staff])

    useEffect(() => {

        const abortController = new AbortController();

        const chosenDay = dayjs(date)
        setTimes(chosenDay)
        getBookings(chosenDay)

        //Effect Clean Up
        return () => {
            abortController.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingInfo, location, staff, chosenLocation])

    const [dayFullyBooked, setDayFullyBooked] = useState(false);
    useEffect(() => {

        if (renderTimes) {
            if (renderTimes.every(time => time.type === 'disabled')) {
                setDayFullyBooked(true)
            }
        }

    }, [renderTimes])

    const updateContext = (val) => {

        updateBooking({

            action: {
                type: 'redirect',
                to: 'next-step'
            },
            info: {
                time: {
                    title: val.title,
                    id: Number(val.id),
                },
            },
        },
            { //update details
                category: 'time',
                toastMessage: `Time: ${val.title}`,
            }
        )
    }

    return (
        <>
            {renderTimes ?
                <List
                    handleClick={updateContext}
                    type={'time'}
                    options={[...renderTimes]}
                />
                : <LoadingScreen>Fetching time slots...</LoadingScreen>
            }

            <Modal
                isVisible={dayFullyBooked}
                shade={true}
            >
                <div
                    style={{
                        padding: '0 1em 1em 1em'
                    }}
                >
                    <h3
                        style={{ textAlign: 'center' }}
                    >Sorry, this day is fully booked</h3>
                </div>
                <PrimaryBtn
                    onClick={() => goToPrevious()}
                    fullWidth
                >
                    <LeftChevron />
                    Change Date
                </PrimaryBtn>
            </Modal>

        </>
    );
}
