import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import React, { useContext } from 'react'
import { ShopContext } from '../Booking/ShopContext'

export function useSearchParams(searchParam) {

    const location = useLocation()

    let myLocation = queryString.parse(location.search)
    let { service, staff, date, time } = myLocation

    return {
        service,
        staff,
        date,
        time
    }
}

export const createUID = (length) => {

    return `${'x'.repeat(length)}`.replace(/[xy]/g, (c) => {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });

}

export const priceToString = (amount) => {
    let res = (amount / 100).toString()
    let price = (amount / 100)

    if (res.length == 1 || res[1].length < 3) {
        // Set the number to two decimal places
        price = price.toFixed(2);
        return price
    } else {
        return price
    }
}


export function useShopInfoGetter(type, subjectId) {
    const { shopInfo } = useContext(ShopContext)

    const val = shopInfo[type] && shopInfo[type].find(item => item.id === subjectId)
    return val ? val : false

}