import React, { useState, useEffect, useContext } from 'react'
import { BusinessContext } from '../App'
import firebase from '../project-config'
import { useParams } from 'react-router-dom'
import getImages from '../Helpers/ImageHelpers'
import LoadingScreen from '../Components/LoadingScreen'

const db = firebase.firestore()
const ShopContext = React.createContext([{}, () => { }]);
const ShopContextProvider = (props) => {

    const { setBusinessInfo, setTheme } = useContext(BusinessContext);

    let { id } = useParams();
    const [shopInfo, setShopInfo] = useState({})
    const [ready, setReady] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [images, setBrandImages] = useState({})


    const query = db.collection(`public`).doc(id)

    let getShopContext = new Promise((resolve, reject) => {
        let data = {
            id
        }
        query.get().then(doc => {
            if (doc.exists) {

                data = {
                    ...data,
                    ...doc.data()
                }

                resolve(data)

            } else {
                reject('failure to receive shop info')
            }
        })
            .catch((err) => {
                reject('error')
            })
    })

    const setImages = async (publicId) => {
        const coverImage = await getImages(publicId, 'cover')

        const galleryImages = await getImages(publicId, 'gallery')

        const logo = await getImages(publicId, 'logo')

        setBrandImages({ coverImage, galleryImages, logo })

    }



    useEffect(() => {

        getShopContext
            .then((data) => {
                setShopInfo(data)
                data.themeColors && setTheme(data.themeColors)
                data.brandColor !== undefined && setBusinessInfo(prevState => {
                    return { ...prevState, brandColor: data.brandColor }
                })
                setImages(data.publicId)
                setReady(true)
            })
            .catch((error) => {
                setNotFound(true)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <ShopContext.Provider value={{ shopInfo, setShopInfo, images }}>
            {ready ?
                <>

                    {props.children}
                </>
                : notFound ?
                    <NoShop />
                    : <LoadingScreen>
                        Finding your shop
                    </LoadingScreen>
            }
        </ShopContext.Provider>
    )
}

function NoShop() {
    return (
        <div
            style={{
                minWidth: '100vw',
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'whitesmoke'
            }}
            className="zyppd-background">
            <h1>Sorry, there's no shop here</h1>
        </div>
    )
}

export { ShopContext, ShopContextProvider }