import { useState, useEffect, useContext } from 'react'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

import toast from 'react-hot-toast'

import { ShopContext } from '../Booking/ShopContext'
import { BookingContext } from '../Booking/BookingContext'

import firebase from 'firebase'

const useBooking = () => {

    const location = useLocation()
    const history = useHistory()

    const { shopInfo } = useContext(ShopContext)
    const [bookingInfo, setBooking] = useContext(BookingContext)

    const hasStaff = shopInfo.staff !== undefined && shopInfo.staff.filter(st => st.disabled !== true).length > 1
    const isRemote = shopInfo.remote !== undefined && shopInfo.remote === true

    const validLocations = shopInfo?.locations?.length > 1 && shopInfo.locations.filter(location => {
        return location
            .disabled !== true
    })
    const hasLocations = validLocations?.length > 1 && shopInfo.remote !== true

    let stepsInit = [
        {
            title: 'location',
            name: 'Location',
            display: hasLocations,
            index: 0
        },
        {
            title: 'service',
            name: 'Service',
            display: true,
            index: 1
        },
        {
            title: 'staff',
            name: 'Staff',
            display: hasStaff,
            index: 2
        },
        {
            title: 'date',
            name: 'Date',
            display: true,
            index: 3

        },
        {
            title: 'time',
            name: 'Time',
            display: true,
            index: 4

        },
        {
            title: 'terms_and_conditions',
            name: 'Terms and Conditions',
            display: !!shopInfo.termsAndConditions || false,
            index: 4

        },
        {
            title: 'customer_address',
            name: 'Address',
            display: isRemote,
            index: 5,

        },
        {
            title: 'customer_details',
            name: 'Customer Details',
            display: true,
            index: 6

        },
        {
            title: 'customer_payment',
            name: 'Payment',
            display: true,
            index: 7
        }
    ]

    const stepNames = stepsInit
        .filter(section => section.display !== false)
        .map(step => step.name)
    const steps = stepsInit
        .filter(section => section.display !== false)
        .map(step => step.title)

    function goToNextStep() {

        const currentStep = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        const currPos = steps.indexOf(currentStep)
        const nextStep = `/${shopInfo.url}/booking/${steps[currPos + 1]}`
        firebase.analytics().logEvent(`${currentStep}_selected`)
        history.push(nextStep)

    }


    async function updateBooking({ action, info, }, updateDetails, otherInfo = {}) {

        if (action.type === 'clear') return setBooking({})

        goToNextStep()

        updateDetails.toastMessage !== 'none' && toast.success(updateDetails.toastMessage)

        return setBooking({
            ...bookingInfo,
            ...info,
            ...updateDetails,
            ...otherInfo,
            [updateDetails.category]: {
                ...info[updateDetails.category]
            }
        })

    }






    function goToPrevious() {
        const currentStep = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

        const withCurrentStep = stepsInit.filter(step => step.display === true).map(step => {
            if (step.title === currentStep) {
                return {
                    ...step,
                    current: true,
                }
            }
            return step
        })

        let prevStep = currentStep === steps[0] ? false : withCurrentStep[withCurrentStep.findIndex(step => step.current) - 1].title
        prevStep ? history.push(`/${shopInfo.url}/booking/${prevStep}`) : history.push(`/${shopInfo.url}`)

    }

    const [prevStep, setPrevStep] = useState()
    const [prevStepName, setPrevStepName] = useState()

    async function removeFutureInfo() {
        const currentStep = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        const currPos = steps.indexOf(currentStep)
        setPrevStep(steps[currPos - 1])
        setPrevStepName(stepNames[currPos - 1])


        async function getNewBookingContext() { // Remove information from future booking steps

            const stepsInContext = Object.keys(bookingInfo).filter(step => steps.includes(step))

            let newBookingContext = {
                ...bookingInfo
            }


            for (let step in stepsInContext) {
                if (step >= currPos) {
                    newBookingContext[stepsInContext[step]] = false
                }
            }

            return newBookingContext
        }

        const newContext = await getNewBookingContext()
        setBooking(newContext)

    }

    useEffect(() => {
        removeFutureInfo()

        if (location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === steps[0]) {
            localStorage.removeItem(`${shopInfo.url}-bookingData`) // fixes a bug in safari that fucked shit up, don't ask me why
            localStorage.removeItem(`payment_ids`) // fixes a bug in safari that fucked shit up, don't ask me why
        }

    }, [location])

    const [chosenLocation, setChosenLocation] = useState(true);

    useEffect(() => {
        if (bookingInfo?.location?.id) {
            const currLocation = shopInfo.locations && shopInfo.locations.find(loc => loc.id === bookingInfo.location.id);
            setChosenLocation(currLocation)
        } else {
            setChosenLocation(false)
        }
    }, [bookingInfo, location])

    return {
        bookingInfo,
        updateBooking,
        goToPrevious,
        prevStep,
        prevStepName,
        hasStaff,
        hasLocations,
        isRemote,
        stepsInit,
        steps,
        chosenLocation
    }
}

export default useBooking