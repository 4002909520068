import React, { useState, useContext, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ShopContext } from '../Booking/ShopContext'
import useBooking from '../Helpers/useBooking'
import { SwapHorizRounded as SwapHorizontle, ChevronLeftRounded as LeftChevron, RoomRounded as LocationIcon, ChevronRightRounded as RightChevron, ArrowRightRounded as RightArrow } from '@material-ui/icons';
import { ListItem, P, Modal, PrimaryBtn, H3, H2 } from 'zyppd-bits'
import { BookingProgress } from './Tracker'
import { priceToString } from '../Helpers/Helpers'
const barHeight = '3em'

const Header = styled.div`
    padding: .5em;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.foreground};
    h3 {
        margin-right: 1em;
        color: ${({ theme }) => theme.brandColor};
    }
`


const SectionContainer = styled.section`
    background: ${({ theme }) => theme.background};
    border: ${({ theme }) => `.2em solid ${theme.middleground}`};
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: ${({ theme }) => theme.shadow};
    position: relative;
    max-height: 800px;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    @media(min-width: 600px){
        height: calc(100vh - ${barHeight});
    }
`

const Logo = styled.img`
    max-height: 2em; 
    border-radius: ${({ theme }) => theme.borderRadius};
`


export function Section({ type = "", options, next, children, prev, bookingInfo }) {

    const { goToPrevious, prevStepName } = useBooking();

    const { shopInfo, images } = useContext(ShopContext);

    const containerEl = useRef(null);

    useEffect(() => {
        containerEl && containerEl.current.scrollTo(0, 0)
    })


    return (
        <SectionContainer>
            <Header>
                <div
                    onClick={() => goToPrevious()}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}>
                    <div style={{ marginRight: '.25em', padding: '.6em 0em .3em .3em' }}>
                        <LeftChevron />
                    </div>
                    {prevStepName && <p style={{ textTransform: 'capitalize' }}>{prevStepName}</p>}

                </div>
                <Link to={`/${shopInfo.url}`}>

                    {images.logo && images.logo.length > 0 ?
                        <Logo src={images.logo} alt={`${shopInfo.shopName} logo`} />
                        :
                        <motion.h3
                            initial={{ x: 50 }}
                            animate={{ x: 0 }}
                            exit={{ x: -50 }}
                            style={{ textAlign: 'right', maxWidth: '15ch' }}
                        >
                            {shopInfo.shopName}
                            <br />
                        </motion.h3>

                    }
                </Link>
            </Header>

            <div style={{
                height: `calc(100%)`,
                overflowY: 'scroll',
                padding: '.25em',
            }}
                ref={containerEl}
            >
                {children}
                <div style={{
                    height: '50vh'
                }}></div>
            </div>
            <BookingProgress />

        </SectionContainer>
    )
}


const UL = styled.ul`
    li {
        display: flex;
        align-items: center;
        transition: box-shadow .1s ease-in;
=            .main-content{
                margin-left: 1em;
            }
            
            &.positive{
                cursor: pointer;
            }
        .list-item-aside{
            height: 100%;
            margin-left: auto;
            margin-right: -.5em;
            display: flex;
            align-items: center;
            &.warning{
                position: relative;
                left: 1em;
                .message{
                    max-width: calc(10ch + 1em);
                }
            }
            
            .message{
                margin-right: .5em;
                text-align: right;
                font-size: 0.8em;
                margin-left: auto;
                &.disabled{
                    margin-right: 1em;
                    font-size: 1em;
                }
            }
        }

        .disabled.message{
            margin-left: auto;
        }

        // For an options list that doesn't move to another page, i.e Months carousel
        &.selected{
            background: ${({ theme }) => theme.brandAccent};
            color: ${({ theme }) => theme.foreground};
            transition: color background .4s ease-out;
        }   

        

        .description{
            margin-top: .25em;
            display: inline-block;
            width: 100%;
        }
        
        .address{
            display: flex;
            padding: .5em;
            margin: .5em 0;
            width: 100%;
            p {
                font-size: 80%;
            }
        }
        .price{
            margin-right: 1em;
            height: 100%;
        }
        .clickable{
            cursor: pointer;
        }   
        
        small {
            opacity: .7;
        }
    }
      
`

const ListContainer = ({ option, disabled, i, children, next, handleClick }) => {

    const { type } = option //default, positive, negative, warning
    const DESCRIPTION_MAX_CHAR_LENGTH = 80
    const [showFullDescription, setShowFullDescription] = useState(false);

    return (
        <AnimatePresence>

            <ListItem
                type={type}
                key={`${i}–${option.title}`}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{
                    delay: 0.03 * i
                }}
            >

                {
                    option.price ?
                        <div
                            className="price clickable"
                            onClick={type !== 'warning' ? () => handleClick(option) : null}

                        >
                            {option.deposit === true &&
                                <small>Deposit</small>
                            }
                            <H3>£{option.price}</H3>
                        </div>
                        : null
                }


                {
                    typeof option === 'object' ?
                        <>
                            <RightArrow type={type} style={{ margin: '0 .2em 0 -.5em' }} />
                            <div>
                                {option.deposit === true && option.priceRange && option.priceRange.length > 0 ?
                                    <small
                                        onClick={type !== 'warning' ? () => handleClick(option) : null}
                                        className="clickable"
                                    >
                                        {!option.priceRange[1] && 'From '} £{priceToString(option.priceRange[0])} {option.priceRange[1] && `- £${priceToString(option.priceRange[1])}`}
                                    </small>
                                    : null
                                }
                                <H3
                                    className="title clickable"
                                    onClick={type !== 'warning' ? () => handleClick(option) : null}

                                >{option.title}</H3>
                                {
                                    option.timeLength &&
                                    <small
                                        onClick={type !== 'warning' ? () => handleClick(option) : null}
                                        className="clickable"

                                    >
                                        About {option.timeLength} minutes
                                    </small>
                                }
                                {option.description &&
                                    <>
                                        <div
                                            className="description clickable"
                                            onClick={() => type !== 'warning' ? handleClick(option) : null}
                                        >
                                            <P>
                                                {option.description.substring(0, DESCRIPTION_MAX_CHAR_LENGTH)}
                                                {option.description.length > DESCRIPTION_MAX_CHAR_LENGTH ? '. . .' : ''}
                                            </P>
                                        </div>
                                        {option.description.length > DESCRIPTION_MAX_CHAR_LENGTH &&
                                            <small
                                                onClick={() => setShowFullDescription(!showFullDescription)}
                                                className="clickable"
                                            >
                                                Read More
                                            </small>
                                        }
                                    </>
                                }


                                {option.address && !option.addressHidden &&
                                    <div
                                        className="address clickable"
                                        onClick={() => type !== 'warning' ? handleClick(option) : null}
                                    >
                                        <LocationIcon />
                                        <div>
                                            {Object.values(option.address).map(line => <p key={line}>{line}</p>)}
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                        :
                        <H2>{option}</H2>
                }

                {showFullDescription &&
                    <Modal
                        isVisible={showFullDescription}
                        title={option.title}
                        close={() => setShowFullDescription(false)}
                        shade={true}
                    >
                        <P>
                            {option.description}
                        </P>
                        {type !== 'warning' &&
                            <PrimaryBtn
                                fullWidth={true}
                                type={type}
                                onClick={() => type !== 'warning' ? handleClick(option) : setShowFullDescription(false)}
                                style={{
                                    margin: '.5em 0'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    className="clickable"
                                >
                                    Choose
                                    <RightChevron />
                                </div>
                            </PrimaryBtn>
                        }
                    </Modal>
                }

                {type !== 'warning' && type !== 'disabled' ?
                    <RightChevron
                        type={type}
                        onClick={type !== 'warning' ? () => handleClick(option) : null}
                        className="clickable"
                    />
                    : <div></div>
                }
                {type === 'disabled' && option.message &&
                    <p className={`${type} message`}
                        dangerouslySetInnerHTML={{ __html: option.message }}
                    >
                    </p>
                }
                {type === 'warning' &&
                    <figure
                        className={` list-item-aside`}
                        onClick={type === 'warning' ? () => handleClick(option) : null}
                    >
                        {option.message &&
                            <p className={`message`}
                                dangerouslySetInnerHTML={{ __html: option.message }}
                            >
                            </p>
                        }
                        {type !== 'disabled' && type === 'warning' && <SwapHorizontle />}
                    </figure>
                }
            </ListItem >
        </AnimatePresence >

    )
}




export function List({ options, next, type, title, handleClick }) {

    const [inputs, setInputs] = useState(options);
    useEffect(() => {
        setInputs(options)
    }, [options]);

    const [popUpIsVisible, setPopUpIsVisible] = useState(false)
    const [popUpOptions, setPopUpOptions] = useState([])

    const togglePopUp = (details) => {
        if (!details.switchWhat) return
        const { switchWhat, switchFrom, overflowBookings } = details
        setPopUpIsVisible(!popUpIsVisible)
        setPopUpOptions({ details, switchWhat, switchFrom, overflowBookings })
    }

    return (
        <div className="options">
            {popUpIsVisible &&
                <PopUpSwitcher
                    isVisible={popUpIsVisible}
                    close={() => setPopUpIsVisible(!popUpIsVisible)}
                    options={popUpOptions}
                />
            }

            {title && <H2 style={{ marginBottom: '1em' }}>{title}</H2>}
            <UL className="options-list">
                <AnimatePresence>
                    {
                        inputs.map((option, i) => {
                            const { type } = option //default, positive, negative, warning
                            function click() {
                                if (type === 'disabled') return null
                                if (type === 'warning') return togglePopUp
                                return handleClick
                            }
                            const doClick = click()

                            return (

                                <ListContainer
                                    style={{
                                        pointerEvents: inputs.disabled ? 'none' : 'auto',
                                        cursor: inputs.disabled ? 'auto' : 'pointer'
                                    }}

                                    handleClick={doClick}
                                    type={type}
                                    option={option}
                                    i={i}
                                    key={option.title}
                                />

                            )
                        })

                    }
                </AnimatePresence >

            </UL>
        </div >
    )

}


const IconButton = styled.button`
    background: ${({ theme, type }) => type !== 'default' ? theme['stateColors'][type] : 'none'};
    box-shadow: ${({ theme, type }) => type !== 'default' ? theme.shadow : 'none'};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: none;
    padding: .3em;
    margin-right: ${({ margins }) => margins.right};
    margin-left: ${({ margins }) => margins.left};
    margin-top: ${({ margins }) => margins.top};
    margin-bottom: ${({ margins }) => margins.bottom};
`
const IconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    path {
        fill: ${({ theme, type, button }) => button && type !== 'default' ? theme.textColor : theme['stateColors'][type]};
    }
`

export const Icon = ({ children, type = 'positive', alt = "Icon", button = false, onClick, margins = { top: '0', left: '0', right: '0', bottom: '0' } }) => {

    return button === true ? (
        <IconButton type={type} margins={margins} onClick={onClick ? () => onClick() : null}>
            <IconBox type={type} button={button}>
                {children}
            </IconBox>
        </IconButton>

    ) : (
        <IconBox type={type} button={button}>
            {children}
        </IconBox>
    )

}

export const PopUpSwitcher = ({ options, isVisible, close }) => {

    const { details, switchWhat, switchFrom, overflowBookings } = options
    const { switchOptions } = details
    const { bookingInfo, updateBooking } = useBooking()

    const changeBooking = (data) => {


        updateBooking({
            action: {
                type: 'redirect',
                to: 'next-step'
            },
            info: {
                [switchWhat]: {
                    ...data
                },
                [switchFrom]: {
                    ...bookingInfo[switchFrom],
                    id: details.id,
                    title: details.title,
                    overflowBookings
                }
            },
        },
            {
                // ...bookingInfo.noUpload,
                category: switchWhat,
                toastMessage: `${switchWhat}: ${data.title}`
            }
        )

        close()
    }

    return (
        <Modal
            isVisible={isVisible}
            shade={true}
            close={close}
            title="Switch Staff"
            style={{
                position: 'relative',
                zIndex: '30'
            }}
        >
            <div className="dialogue-content">
                <UL>
                    {switchOptions.map((option, i) => {
                        return (
                            <ListContainer
                                type={option.type}
                                option={option}
                                i={i}
                                key={i}
                                handleClick={changeBooking}
                            />
                        )

                    })}
                </UL>
            </div>
        </Modal>
    )
}

export const Shade = styled.div`
    position: absolute; 
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: ${({ theme }) => theme.transparentForeground};
`
export const Dialogue = styled.div`
    position: fixed; 
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10; 
    display: flex; 
    justify-content: center;
    align-items: center;
    .dialogue-content{
        width: calc(100% - 4em);
        max-width: calc(500px - 4em);
        background: ${({ theme }) => theme.middleground};
        box-shadow: ${({ theme }) => theme.shadow}; 
        border-radius:  ${({ theme }) => theme.borderRadius}; 
        padding: 1em;
        ul{
            margin-bottom: 1em;
            li{
                margin-bottom: 1em;
            }
        }
    }
    .buttons{
        display: flex;
        align-items: center;
        width: 100%;
        // background: ${({ theme }) => theme.middleground};
        // margin: -1em;
        // padding: 1em;
        button{
            background: none;
            border: none;
        }
        .confirm{
            margin-left: auto;
        }
    }
`

export const Form = styled.form`
display: flex;
flex - direction: column;
input{
    border - radius: ${({ theme }) => theme.borderRadius};
    padding: 1em;
    margin: 1em 0;
}   
`
