import firebase from 'firebase'

const sortImagesAndReturnURL = async (docs) => {

    let sortedBySize = docs.items.sort((a, b) => b.size - a.size)
    let imgs = []

    for await (let img of sortedBySize) {
        let url = await img.getDownloadURL()
        imgs.push(url)
    }

    return imgs
}
const getImages = async (publicId, getWhat, updateWhat, cb) => {

    const storage = firebase.storage();
    let path;
    let update;

    switch (getWhat) {
        case 'cover':
            path = ''
            break;
        case 'gallery':
            path = '/galleryImages'
            break;
        case 'galleryImages':
            path = '/galleryImages'
            break;
        case 'logo':
            path = '/logo'
            break;
        default:
            path = ''
    }

    switch (updateWhat) {
        case 'cover':
            update = 'coverImage'
            break;
        case 'gallery':
            update = 'galleryImages'
            break;
        case 'galleryImages':
            update = 'galleryImages'
            break;
        case 'logo':
            path = '/logo'
            break;
        default:
            update = ''
    }


    return storage
        .ref(`${publicId}${path}`)
        .listAll()
        .then(async (docs) => {

            const imgs = await sortImagesAndReturnURL(docs)

            cb && cb()
            return imgs
        })
        .catch(err => {
            console.log(err)
            return []
        })
}

export default getImages