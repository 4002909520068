import React, { Suspense, useContext } from 'react'
import {
    Route,
    useParams,
    Switch,
    useHistory
} from 'react-router-dom'
// import queryString from 'query-string'
import styled from 'styled-components';

import { ShopContextProvider } from './ShopContext'
import { BookingContextProvider } from './BookingContext'
import { ShopContext } from './ShopContext'
// import { ModalsContextProvider } from '../Components/Modals'
import LoadingScreen from '../Components/LoadingScreen'
import Service from './Sections/Service'
import Staff from './Sections/Staff'
import Date from './Sections/Date'
import Time from './Sections/Time'
import useBooking from '../Helpers/useBooking'
import { Section } from '../Components/Modules'

import { ChevronLeftRounded as LeftChevron } from '@material-ui/icons';
const CustomerDetailsContainer = React.lazy(() => import('./Sections/CustomerDetails/CustomerDetailsContainer'))
const CustomerAddress = React.lazy(() => import('./Sections/CustomerAddress'))
const CustomerDetails = React.lazy(() => import('./Sections/CustomerDetails'))
const TermsAndConditions = React.lazy(() => import('./Sections/TermsAndConditions'))
const Payment = React.lazy(() => import('./Sections/Payment'))
const ConfirmBooking = React.lazy(() => import('./ConfirmBooking'))
const Thankyou = React.lazy(() => import('./Thankyou'))
const ShopLanding = React.lazy(() => import('./Sections/ShopLanding'))
const Location = React.lazy(() => import('./Sections/Location'))

const Container = styled.div`
    height: 100vh;
    display: flex; 
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background .4s ease-out;
    align-items: center;
    .booking-container{
        width: 100%;
        @media(min-width: 600px){
            max-width: 500px;
        }
    }
`

export default function CreateBookingContainer({ businessInfo }) {
    let { id } = useParams();

    return (
        <Suspense
            fallback={<LoadingScreen></LoadingScreen>}
        >
            <ShopContextProvider>

                <BookingContextProvider>

                    <Route exact path={`/${id}`}>
                        <ShopLanding id={id} businessInfo={businessInfo} />
                    </Route>

                    <Route path={`/${id}/booking/`} id>
                        <Container>
                            <BookingContainer id={id} />
                        </Container>
                    </Route>

                    <Route exact path={`/${id}/thankyou`}>
                        <Thankyou />
                    </Route>
                </BookingContextProvider>
            </ShopContextProvider>

        </Suspense>


    );
}


const BookingContainer = ({ children, id, location }) => {

    // const { shopInfo } = useContext(ShopContext);
    const { bookingInfo, updateBooking, hasStaff } = useBooking();


    return (

        <div className="booking-container" style={{ position: 'relative' }}>
            {/* <ModalsContextProvider> */}
            <Section bookingInfo={bookingInfo}>
                <Switch>
                    <Route exact path={`/${id}/booking/location`} id>
                        <Location
                            id={id}
                            bookingInfo={bookingInfo}
                            updateBooking={updateBooking}
                            // TODO: remove location prop from all
                            location={location}
                            hasStaff={hasStaff}
                        />
                    </Route>
                    <Route exact path={`/${id}/booking/service`} id>
                        <Service
                            id={id}
                            bookingInfo={bookingInfo}
                            updateBooking={updateBooking}
                            location={location}
                            hasStaff={hasStaff}
                        />
                    </Route>

                    <Route exact path={`/${id}/booking/staff`} id>
                        <Staff
                            id={id}
                            bookingInfo={bookingInfo}
                            updateBooking={updateBooking}
                            location={location}
                            hasStaff={hasStaff}
                        />
                    </Route>

                    <Route exact path={`/${id}/booking/date`}>
                        <Date
                            id={id}
                            bookingInfo={bookingInfo}
                            updateBooking={updateBooking}
                            location={location}
                            hasStaff={hasStaff}

                        />
                    </Route>

                    <Route exact path={`/${id}/booking/time`}>
                        <Time
                            id={id}
                            bookingInfo={bookingInfo}
                            updateBooking={updateBooking}
                            location={location}
                            hasStaff={hasStaff}

                        />
                    </Route>

                    <Route exact path={`/${id}/booking/customer_address`}>
                        <CustomerAddress
                            id={id}
                            bookingInfo={bookingInfo}
                            updateBooking={updateBooking}
                            location={location}
                        />
                    </Route>

                    <Route exact path={`/${id}/booking/terms_and_conditions`}>
                        <TermsAndConditions
                            id={id}
                            bookingInfo={bookingInfo}
                            updateBooking={updateBooking}
                            location={location}
                        />
                    </Route>

                    <CustomerDetailsContainer
                        id={id}
                        bookingInfo={bookingInfo}
                        location={location}
                    >
                        <Route exact path={`/${id}/booking/customer_details`}>
                            <CustomerDetails
                                id={id}
                                bookingInfo={bookingInfo}
                                updateBooking={updateBooking}
                                location={location}
                            />
                        </Route>
                        <Route exact path={`/${id}/booking/customer_payment`}>
                            <Payment
                                id={id}
                                bookingInfo={bookingInfo}
                                updateBooking={updateBooking}
                                location={location}
                            />
                        </Route>
                    </CustomerDetailsContainer>

                    <Route exact path={`/${id}/booking/confirm`}>
                        <ConfirmBooking
                            id={id}
                            bookingInfo={bookingInfo}
                            updateBooking={updateBooking}
                            location={location}
                        />
                    </Route>

                </Switch>

            </Section>
            {/* </ModalsContextProvider> */}
        </div>
    )
}
