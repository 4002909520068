import React, { useState, useEffect, useContext } from 'react'
import { List } from '../../Components/Modules'
import { ShopContext } from '../ShopContext'
import LoadingScreen from '../../Components/LoadingScreen'
// import useBooking from '../CreateBookingContainer'
import useBooking from '../../Helpers/useBooking'

import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)

export default function SetDate({ id, bookingInfo, updateBooking, hasStaff }) {


    const currentTimeZone = 'Europe/London'
    const { shopInfo } = useContext(ShopContext)
    const [days, setDays] = useState(shopInfo.days);
    const { chosenLocation } = useBooking();

    useEffect(() => {

        const currLocationDays = typeof chosenLocation === 'object' ? chosenLocation.days : shopInfo.days
        setDays(currLocationDays)

    }, [chosenLocation])


    const [staff] = useState(() => bookingInfo.staff ? bookingInfo.staff.id : '000')
    const [daysList, setDaysList] = useState([])


    async function getDaysForChosenMonth(today) {

        async function parseDateObj(day) {

            let title = `${day.format('dddd')} ${day.format('Do')} ${day.format('MMMM')}`
            const isToday = day.isToday()

            if (isToday) {
                title = "Today"
            }
            return {
                title,
                id: day.format('L'),
            }
        }


        let daysArr = []

        for (let i = 0; i < shopInfo.numOfBookingsDays; i++) {
            let day = dayjs(today + (i * 86400000))
            daysArr.push(await parseDateObj(day))
        }

        return daysArr

    }

    const getOpeningAndClosingTimes = (day) => {
        const { firstBookingTime, lastBookingTime } = days.find(shopInfoDay => shopInfoDay.day === day)
        return { firstBookingTime, lastBookingTime }
    }

    const todayDetails = (day, today) => {
        today = dayjs(today)
        const currentTime = `${today.hour()}${today.minute() < 10 ? 0 : ''}${today.minute()}`
        const { lastBookingTime } = days.find(d => d.day === day)
        const closedForToday = Number(lastBookingTime.replace(":", "")) < Number(currentTime)
        const lastBookingTimeStr = lastBookingTime === "24:00" ? "00:00" : lastBookingTime

        if (closedForToday) {
            return {
                type: 'disabled',
                description: `Closed at ${lastBookingTimeStr}`
            }
        } else {
            return {
                description: `Closes at ${lastBookingTimeStr}`
            }
        }
    }


    const disableClosedDays = async (daysArr, today) => {

        const daysClosed = days.filter(day => day.open === false).map(day => day.day) // return an array of numbers of days closed
        let now = dayjs()

        if (shopInfo.minimumAdvanceBooking) {
            now = now.add(shopInfo.minimumAdvanceBooking, 'm')
        }

        return daysArr.map((day, i) => {


            if (shopInfo.holidays) {
                const hols = shopInfo.holidays.map(hol => hol.date)

                if (hols.some(hol => hol !== undefined && dayjs(hol).isSame(day.id, 'day'))) {
                    return {
                        ...day,
                        type: 'disabled',
                        description: 'Closed',
                        closed: true
                    }
                }
            }

            const isToday = dayjs(day.id).isToday()

            const dayOfWeek = dayjs(day.id).startOf('day').day()

            // console.log(
            //     daysClosed.includes(dayOfWeek), "day", dayjs(day.id).format('dddd'), dayOfWeek,
            //     {
            //         ...day,
            //         type: 'disabled',
            //         description: 'Closed',
            //         closed: true
            //     })

            if (daysClosed.includes(dayOfWeek)) {
                return {
                    ...day,
                    type: 'disabled',
                    description: 'Closed',
                    closed: true
                }
            }

            const { firstBookingTime, lastBookingTime } = getOpeningAndClosingTimes(dayOfWeek)

            // if (shopInfo.minimumAdvanceBooking) {
            //     now = now.add(shopInfo.minimumAdvanceBooking, 'm')
            // }

            const lastAvailableSlot = lastBookingTime.split(":")
            let closingTime = dayjs(day.id).hour(lastAvailableSlot[0]).minute(lastAvailableSlot[1]).valueOf() - (((bookingInfo.service.amountOfTime) * shopInfo.timeSlotLength) * 60000)



            if (now.valueOf() >= closingTime - ((bookingInfo.service.amountOfTime * shopInfo.timeSlotLength) * 60000)) {
                return {
                    ...day,
                    type: 'disabled',
                    description: 'Not taking anymore bookings for this day',
                    closed: true
                }
            }

            if (isToday) {

                return {
                    ...day,
                    ...todayDetails(dayOfWeek, today)
                }
            }

            const lastBookingTimeStr = lastBookingTime === "24:00" ? "00:00" : lastBookingTime
            return {
                ...day,
                description: `${firstBookingTime} – ${lastBookingTimeStr}`
            }
        })

    }


    function getAlternativeStaff(day, chosenStaff) {

        const alternateStaff = bookingInfo.alternateStaff
            .filter(member => member.id !== staff)
            .filter(member => !member.daysOff || !member.daysOff.includes(dayjs(day.id).day()))

        let messageAppendix = alternateStaff.length > 1 ? `or ${alternateStaff.length - 1} other${alternateStaff.length - 1 > 1 ? 's' : ''}` : ''


        if (alternateStaff.length > 0) {
            return {
                ...day,
                type: 'warning',
                message: `Switch to ${alternateStaff[0].title} ${messageAppendix}`,
                switchWhat: 'staff',
                switchOptions: alternateStaff,
                switchFrom: 'date',
                description: `${chosenStaff.title} is off`,
            }

        } else {
            return {
                ...day,
                type: 'disabled',
                description: `${chosenStaff.title} is off`,
            }
        }

    }

    async function setStaffDaysOff(daysArr) {
        const chosenStaff = shopInfo.staff.find(member => member.id === staff) // gets staff that matches id in url search param

        const daysOff = chosenStaff.daysOff || []
        return daysArr.map(day => {
            const thisDayAsNum = dayjs(day.id).day()


            if (daysOff.includes(thisDayAsNum) && day.type !== 'disabled') {
                return getAlternativeStaff(day, chosenStaff)

            } else return day
        })
    }

    async function filterStaff(daysArr, today) {
        const withStaffDayOff = await setStaffDaysOff(daysArr)
        return withStaffDayOff
    }


    async function getDates() {


        const d = new Date().getTime()
        const days = await getDaysForChosenMonth(d) // Gets days starting from today, adds 'Today' title

        if (days.length > 0) {
            const withClosedDays = await disableClosedDays(days, d)

            if (!hasStaff || staff === '000') return withClosedDays

            const withStaffFilter = await filterStaff(withClosedDays, d)

            return withStaffFilter
        }

    }

    useEffect(() => {

        getDates().then((datesArr) => {
            setDaysList(datesArr)
        })

    }, [staff, shopInfo, days])



    const updateContext = (val) => {

        const alternateStaff = shopInfo.staff !== undefined
            && shopInfo.staff.length > 1 && bookingInfo.alternateStaff ?
            bookingInfo.alternateStaff
                .filter(staff => {
                    return staff.daysOff ?
                        !staff.daysOff.includes(dayjs(val.id).day())
                        && staff.disabled !== true : staff.disabled !== true
                }
                )
            : [] // Get the staff that offer this service and are available on this day


        let output = val;

        if (val === 'Today') {
            output = daysList[0]
        } else if (val === 'Tomorrow') {
            output = daysList[1]

        }

        updateBooking(
            {
                action: {
                    type: 'redirect',
                    to: 'next-step'
                },
                info: {
                    date: {
                        title: output.title || output,
                        id: output.id || output,
                    },
                },
            },
            { // Update details
                category: 'date',
                toastMessage: `Date: ${output.title || output}`,
            },
            { alternateStaff: [...alternateStaff] }
        )

    }

    return daysList ? (
        <List
            handleClick={updateContext}
            type={'date'}
            options={[...daysList]}
        />


    ) : <LoadingScreen>Fetching Dates...</LoadingScreen>;
}

