import React, { useState, useContext, useEffect } from 'react'
import { ShopContext } from './ShopContext'
import useBooking from '../Helpers/useBooking'
import { useHistory, useLocation } from 'react-router-dom'
import LoadingScreen from '../Components/LoadingScreen'

const BookingContext = React.createContext([{}, () => { }]);

const BookingContextProvider = (props) => {

    const history = useHistory();
    const { shopInfo } = useContext(ShopContext);
    const location = useLocation();
    const { steps } = useBooking();
    const [booking, setBooking] = useState({})
    const [ready, setReady] = useState(false)

    useEffect(() => {
        if (booking && Object.keys(booking).length) {
            localStorage.setItem(`${shopInfo.id}-bookingData`, JSON.stringify(booking))
        }
    }, [booking])

    useEffect(() => {
        // localStorage.clear()
        const rawBookingData = localStorage.getItem(`${shopInfo.id}-bookingData`);
        const bookingData = JSON.parse(rawBookingData);

        if (bookingData) {
            setBooking(JSON.parse(rawBookingData))
            setReady(true)

        } else {
            setBooking({})
            if (location.pathname.includes(`/${shopInfo.id}/booking`) && location.pathname !== `/${shopInfo.id}/thankyou`) {
                history.push(`/${shopInfo.id}/booking/${steps[0]}`)
            }
        }


        setReady(true)
    }, [location.pathname])



    return (
        <BookingContext.Provider value={[booking, setBooking]}>
            {ready ?
                props.children
                : <LoadingScreen></LoadingScreen>
            }
        </BookingContext.Provider>
    )
}

export { BookingContext, BookingContextProvider }